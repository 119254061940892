<template>
    <div id="login">
        <jy-login :bg="loginBg" :logo="loginLogo" topName="数字固废公共服务云平台" name="智慧固废" @login="login"></jy-login>
    </div>
</template>
<script type="text/javascript">
    import loginBg from "@/assets/login_bg.png";
    import loginLogo from "@/assets/login_logo.svg";
    export default {
        data() {
            return {
                loginBg,
                loginLogo
            };
        },
        methods: {
            login(val) {
                let option = {
                    userNa: val.userName,
                    passwd: val.password
                };
                let url = "/sys/user/login";
                this.$http.post(url, option).then(data => {
                    console.log(data);
                    this.$util.setStor("token", data.detail.token);
                    this.$message({
                        message: "登录成功",
                        type: "success"
                    });
                    this.$store.commit("setInfo", data.detail);
                    console.log(this.$store.state.info);
                    setTimeout(() => {
                        this.$router.push({ name: "admin" });
                    }, 300);
                });
            }
        }
    };
</script>
<style type="text/css" lang="scss">
    .login .head img {
        width: 100px;
    }
</style>
